<template>
  <div>
    <div class="logo-horizontal">
      <router-link to="/"
        ><img src="@/assets/horizontal.png" alt=""
      /></router-link>
    </div>
    <div class="container">
      <div v-if="!validation">
        <h1>Bejelentkezés</h1>

        <h2 class="label">Felhasználónév</h2>
        <b-form-input
          v-model="name"
          maxlength="30"
          placeholder="Írd be a felhasználóneved..."
        ></b-form-input>

        <div>
          <h2 class="label">Jelszó</h2>
          <div class="flexi">
            <b-form-input
              :type="passwordType"
              v-model="password"
              maxlength="30"
              placeholder="Add meg a jeszavad..."
              class="password-input"
            ></b-form-input>
            <div
              v-if="passwordType == 'password'"
              @click="passwordTypeShow(true)"
              class="eye"
              v-b-tooltip.hover
              title="Megmutat"
            >
              <b-icon icon="eye"></b-icon>
            </div>
            <div
              v-else
              @click="passwordTypeShow(false)"
              class="eye"
              v-b-tooltip.hover
              title="Elrejt"
            >
              <b-icon icon="eye-slash"></b-icon>
            </div>
          </div>
        </div>

        <b-form-checkbox
          id="staylogin"
          class="mt-3"
          v-model="stayLoginStatus"
          name="staylogin"
          value="1"
          unchecked-value=""
        >
          Bejelentkezve maradok
        </b-form-checkbox>

        <div v-if="faultText" class="mt-4 fault">{{ faultText }}</div>

        <b-button class="mt-4" variant="primary" @click="loginbtn()"
          >Belépés</b-button
        >

        <div class="buttonborder">
          <div @click="reg()" class="bottommenu">Regisztráció</div>
          <div @click="forgot()" class="bottommenu ml-4">
            Elfelejtett jelszó
          </div>
        </div>
      </div>
      <div v-if="validation">
        <h4 class="mb-4">Email cím még nincs érvényesítve!</h4>
        <p>
          Regisztráció után elküldtünk egy emailt, a benne található linkre
          kattintava érvényesítheted a regisztrációdat. Amennyiben az email nem
          érkezett meg, nézd meg a levélszemét/spam mappában is. Ha továbbra sem
          találod, küldj el egy tetszőleges emailt a regisztrált emailfiókodból
          az emailvalidation@elektrogeek.hu címre és a lehető leggyorsabban
          érvényesítjük a regisztrációdat.
        </p>
        <p>További szép napot!</p>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../util/api";
import { specialChar } from "../util/validation";

export default {
  name: "login",
  data() {
    return {
      name: "",
      password: "",
      passwordType: "password",
      stayLoginStatus: "",
      nameFault: false,
      passwordFault: false,
      validation: false,
      faultText: false,
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },
  watch: {
    name() {
      if (specialChar(this.name)) {
        this.nameFault = true;
      } else {
        this.nameFault = false;
      }
      this.faultText = false;
    },
    password() {
      if (specialChar(this.password)) {
        this.passwordFault = true;
      } else {
        this.passwordFault = false;
      }
      this.faultText = false;
    },
  },
  methods: {
    reg() {
      this.$router.push("/registration");
    },
    forgot() {
      this.$router.push("/forgot");
    },
    loginbtn() {
      if (this.name && this.password) {
        if (this.nameFault && this.passwordFault) {
          let self = this;
          self.$loading(true);
          Api.login(this.name, this.password, this.stayLoginStatus)
            .then(function (response) {
//console.log(response.data);
              if (response.data.token && response.data.token != "validation" && response.data.token != "dontmore") {
                self.$store.commit("setToken", response.data.token);
                self.$router.push("/");
              } else if (response.data.token == "validation") {
                self.validation = true;
              } else if (response.data.token == "dontmore") {
                self.faultText = "10 Sikertelen próbálkozás miatt, 5 percre le vagy tiltva!";
              } else {
                self.faultText = "A felhasználónév és/vagy a jelszó hibás!";
              }
              self.$loading(false);
            })
            .catch((err) => {
              console.log(err);
              self.$loading(false);
            });
        } else {
          this.faultText = "A felhasználónév és/vagy a jelszó hibás!";
        }
      } else {
        this.faultText = "Az összes mező kitöltése kötelező!";
      }
    },
    passwordTypeShow(type) {
      if (type) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    makeToast(title, variant, errorMessage) {
      this.$bvToast.toast(errorMessage, {
        title: title,
        autoHideDelay: 5000,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.flexi {
  display: flex;
  flex-wrap: wrap;
}
.password-input {
  width: 90%;
}
.eye {
  line-height: 2.4rem;
  margin-left: 10px;
  cursor: pointer;
}
.logo-horizontal {
  width: 260px;
  margin: 0 auto;
  margin-top: 20px;
}
.container {
  position: relative;
  max-width: 700px;
  margin: 10px auto;
  padding: 35px 35px 60px 35px;
  color: #444;
  background: white;
}
h1 {
  text-align: center;
}
h2 {
  font-size: 1.7rem;
  margin-top: 30px;
  color: #777;
}
.fault {
  color: red;
}
.buttonborder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #ddd;
}
.bottommenu {
  color: blue;
  cursor: pointer;
}
.bottommenu:hover {
  text-decoration: underline;
}
</style>